import { Link } from "gatsby"
import React from "react"

import { Container, Heading, Layout, SEO, Stack, Text } from "../components"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not found | Jon Milner" />
    <Container width="sm">
      <Stack gap="xl">
        <Text>
          <Link to="/">Back to Home</Link>
        </Text>
        <Heading letterSpacing="narrow" size="4xl" weight="black">
          Page Not <br />
          Found{` `}
          <span aria-label="Magnifying glass" className="emoji" role="img">
            🔍
          </span>
        </Heading>
        <Text>Whatever you're looking for isn't here. Sorry.</Text>
      </Stack>
    </Container>
  </Layout>
)

export default NotFoundPage
